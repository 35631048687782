import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
import { Link } from 'gatsby';
import thumbsup from '@assets/thumbsup-emoji.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className='sidebar-layout'>
      <div className='sidebar-layout__main' markdown='1'>
        <p>{`Have an idea for a new feature? If so, please carefully follow these steps:`}</p>
        <h2>{`Step 1: Check for an existing request`}</h2>
        <p>{`There are over 100 existing feature requests. Someone else probably already wants what you want. Please search through the issue tracker (link below) and if you find what you're looking for, `}<strong>{`please vote with a`}</strong>{` `}<img src={thumbsup} width="20" height="20" style={{
            "verticalAlign": "baseline"
          }} alt='thumbsup' />{` reaction on the topmost description of the issue (`}<a href="https://github.com/blog/2119-add-reactions-to-pull-requests-issues-and-comments">{`learn how`}</a>{`) and press the `}<strong style={{
            "backgroundImage": "linear-gradient(#fcfcfc, #eee)",
            "border": "1px solid #d5d5d5",
            "color": "#333",
            "padding": "0 5px",
            "borderRadius": "3px"
          }}>{`Subscribe`}</strong>{` button to receive updates when progress is made.`}</p>
        <p>{`Comments like "+1" or "when will this be done?" will be ignored. Only `}<img src={thumbsup} width="20" height="20" style={{
            "verticalAlign": "baseline"
          }} alt='thumbsup' />{` reactions on the topmost description will be counted.`}</p>
        <a href='https://github.com/fullcalendar/fullcalendar/issues?q=is%3Aissue+is%3Aopen+sort%3Areactions-%2B1-desc' target='_blank'>
  See all feature requests ranked by demand &raquo;
        </a>
        <h2>{`Step 2: Is it a valid feature?`}</h2>
        <p>{`Ask yourself, is this a feature other people would find useful? Often times the feature you want built is too specific to your application and not generalizable enough for others.`}</p>
        <p>{`Also, is it within the scope of the FullCalendar project? FullCalendar is a front-end widget for displaying events, so any sort of back-end-related feature requests (PHP, MySQL, ASP.net, etc) are out of scope.`}</p>
        <h2>{`Step 3: Describe the feature`}</h2>
        <p>{`Please write a `}<em parentName="p">{`detailed`}</em>{` description of the feature. Please explain why the feature would be useful and how it might be implemented. Assume the reader knows very little about your situation and explain everything.`}</p>
        <p>{`For user-interface features, `}<strong parentName="p">{`please include mockups`}</strong>{` of what you want it to look like.`}</p>
        <p>{`For API improvements, such as new options or methods, please write some example code of how one might use it.`}</p>
        <h2>{`Step 4: Use the issue tracker`}</h2>
        <p>{`If you are confident you have followed all the above steps, enter your feature request into the issue tracker. Please enter only one feature request per ticket and do not combine tickets.`}</p>
        <p><a parentName="p" {...{
            "href": "https://github.com/fullcalendar/fullcalendar/issues"
          }}>{`Visit the issue tracker `}{`»`}</a></p>
      </div>
      <div className='sidebar-layout__sidebar'>
        <div className='sidebar-card'>
  <h3>Premium Support</h3>
  <p>FullCalendar Premium comes with 1 year of email support.</p>
  <Link to='/pricing' className='button button--filled button--blue' mdxType="Link">
    Learn more &raquo;
  </Link>
        </div>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      